import React from 'react';
import soundOnIcon from '@src/img/icon-sound-on.png';
import soundOffIcon from '@src/img/icon-sound-off.png';

export const AudioIcon = ({
  onToggleIcon,
  isPlaying
}: {
  onToggleIcon: () => void;
  isPlaying: boolean
}) => {
  return (
    <div>
      <img
        style={{ width: '30px' }}
        src={isPlaying ? soundOnIcon : soundOffIcon}
        onClick={onToggleIcon}
      />
    </div>
  );
};
