import React, { memo, useEffect, useState } from 'react';
import { AlphaVideo } from '@src/components/AlphaVideo';
import { PAGE_NAME } from '../../constants/common';
import bkgHome from '@src/img/bkg_home.jpg';
import bkgShowFlat from '@src/img/bkg_showflat_hover.jpg';
import bkgSalesOffice from '@src/img/bkg_sales_office_hover.jpg';
import bkgSalesBrochure from '@src/img/bkg_sales_brochure_hover.jpg';
import bkgPriceList from '@src/img/bkg_price_list_hover.jpg';
import bkgSalesArrangements from '@src/img/bkg_sales_arrangements_hover.jpg';
import bkgRegisterOfTransactions from '@src/img/bkg_register_of_transactions_hover.jpg';
import bkgDeedOfMutualCovenant from '@src/img/bkg_deed_of_mutual_covenant_hover.jpg';
import bkgAerialPhotograph from '@src/img/bkg_aerial_photograph_hover.jpg';
import bkgTenderDocument from '@src/img/bkg_tender_document_hover.jpg';
import bkgRegistration from '@src/img/bkg_registration_hover.jpg';
import bkgBallotResult from '@src/img/bkg_ballot_result_hover.jpg';
import bkgSalesBrochureForParkingSpace from '@src/img/bkg_sales_brochure_for_parking_space_hover.jpg';
import bkgParkingSpaceDocument from '@src/img/bkg_parking_space_document_hover.jpg';
import videoYellowMp4 from '@src/video/video-yellow.mp4';
import videoYellowWebm from '@src/video/video-yellow.webm';
import videoYellow2Mp4 from '@src/video/video-yellow2.mp4';
import videoYellow2Webm from '@src/video/video-yellow2.webm';
import videoBlueMp4 from '@src/video/video-blue.mp4';
import videoBlueWebm from '@src/video/video-blue.webm';
import videoCyanMp4 from '@src/video/video-cyan.mp4';
import videoCyanWebm from '@src/video/video-cyan.webm';
import videoCyan2Mp4 from '@src/video/video-cyan2.mp4';
import videoCyan2Webm from '@src/video/video-cyan2.webm';
import videoCyan3Mp4 from '@src/video/video-cyan3.mp4';
import videoCyan3Webm from '@src/video/video-cyan3.webm';
import videoCyan4Mp4 from '@src/video/video-cyan4.mp4';
import videoCyan4Webm from '@src/video/video-cyan4.webm';
import videoPurpleMp4 from '@src/video/video-purple.mp4';
import videoPurpleWebm from '@src/video/video-purple.webm';
import videoPurple2Mp4 from '@src/video/video-purple2.mp4';
import videoPurple2Webm from '@src/video/video-purple2.webm';
import videoPurpleBlueMp4 from '@src/video/video-purple-blue.mp4';
import videoPurpleBlueWebm from '@src/video/video-purple-blue.webm';

import './index.less';

const {
  HOME,
  SHOW_FLAT,
  SALES_OFFICE,
  SALES_BROCHURE,
  PRICE_LIST,
  SALES_ARRANGEMENTS,
  REGISTER_OF_TRANSACTION,
  DEED_OF_MUTUAL_COVENANT,
  AERIAL_PHOTOGRAPH,
  TENDER_DOCUMENT,
  REGISTRATION,
  BALLOT_RESULT,
  SALES_BROCHURE_FOR_PARKING_SPACE,
  PARKING_SPACE_DOCUMENT,
} = PAGE_NAME;

const pageBkgResoucesMap = {
  [HOME]: {
    img: bkgHome,
    video: null,
    className: 'home',
    mp4Src: videoYellowMp4,
    webmSrc: videoYellowWebm,
  },
  [SHOW_FLAT]: {
    img: bkgShowFlat,
    video: 'video-cyan2',
    className: 'show-flat',
    mp4Src: videoCyan2Mp4,
    webmSrc: videoCyan2Webm,
  },
  [SALES_OFFICE]: {
    img: bkgSalesOffice,
    video: 'video-yellow2',
    className: 'sales-office',
    mp4Src: videoYellow2Mp4,
    webmSrc: videoYellow2Webm,
  },
  [SALES_BROCHURE]: {
    img: bkgSalesBrochure,
    video: 'video-purple2',
    className: 'sales-brochure',
    mp4Src: videoPurple2Mp4,
    webmSrc: videoPurple2Webm,
  },
  [PRICE_LIST]: {
    img: bkgPriceList,
    video: 'video-yellow',
    className: 'price-list',
    mp4Src: videoYellowMp4,
    webmSrc: videoYellowWebm,
  },
  [SALES_ARRANGEMENTS]: {
    img: bkgSalesArrangements,
    video: 'video-purple-blue',
    className: 'sales-rrangements',
    mp4Src: videoPurpleBlueMp4,
    webmSrc: videoPurpleBlueWebm,
  },
  [REGISTER_OF_TRANSACTION]: {
    img: bkgRegisterOfTransactions,
    video: 'video-cyan',
    className: 'register-of-transactions',
    mp4Src: videoCyanMp4,
    webmSrc: videoCyanWebm,
  },
  [DEED_OF_MUTUAL_COVENANT]: {
    img: bkgDeedOfMutualCovenant,
    video: 'video-purple-blue',
    className: 'deed-of-mutual-covenant',
    mp4Src: videoPurpleBlueMp4,
    webmSrc: videoPurpleBlueWebm,
  },
  [AERIAL_PHOTOGRAPH]: {
    img: bkgAerialPhotograph,
    video: 'video-purple',
    className: 'aerial-photograph',
    mp4Src: videoPurpleMp4,
    webmSrc: videoPurpleWebm,
  },
  [TENDER_DOCUMENT]: {
    img: bkgTenderDocument,
    video: 'video-cyan4',
    className: 'tender-document',
    mp4Src: videoCyan4Mp4,
    webmSrc: videoCyan4Webm,
  },
  [REGISTRATION]: {
    img: bkgRegistration,
    video: 'video-blue',
    className: 'registration',
    mp4Src: videoBlueMp4,
    webmSrc: videoBlueWebm,
  },
  [BALLOT_RESULT]: {
    img: bkgBallotResult,
    video: 'video-yellow2',
    className: 'ballot-result',
    mp4Src: videoYellow2Mp4,
    webmSrc: videoYellow2Webm,
  },
  [SALES_BROCHURE_FOR_PARKING_SPACE]: {
    img: bkgSalesBrochureForParkingSpace,
    video: 'video-cyan3',
    className: 'sales-brochure-for-parking-space',
    mp4Src: videoCyan3Mp4,
    webmSrc: videoCyan3Webm,
  },
  [PARKING_SPACE_DOCUMENT]: {
    img: bkgParkingSpaceDocument,
    video: 'video-purple2',
    className: 'parking-space-document',
    mp4Src: videoPurple2Mp4,
    webmSrc: videoPurple2Webm,
  },
};

type Props = {
  hoveringPageName: string;
};

export const MenuBackgound = ({ hoveringPageName }: Props) => {
  return (
    <>
      <div
        className="menu-background-wrapper"
        style={{ backgroundColor: '', position: 'fixed' }}
      />
      {Object.keys(pageBkgResoucesMap).map((key, index) => {
        const { img, className, video, mp4Src, webmSrc } = pageBkgResoucesMap[
          key
        ];

        return (
          <BackgroundImageDiv
            img={img}
            isHovered={key === hoveringPageName}
            pageName={key}
            video={video}
            videoMp4={mp4Src}
            videoWebm={webmSrc}
            key={index}
          />
        );
      })}
    </>
  );
};

const BackgroundImageDiv = memo(
  ({
    img,
    isHovered,
    pageName,
    video,
    videoMp4,
    videoWebm,
  }: {
    img: any;
    isHovered: boolean;
    pageName: string;
    video: string | null;
    videoMp4: any;
    videoWebm: any;
  }) => {
    const [animation, setAnimation] = useState('');

    useEffect(() => {
      if (isHovered) {
        setAnimation('fadeIn 0s linear forwards');
      } else {
        setAnimation((prev) => (prev ? 'fadeOut 0s linear forwards' : ''));
      }
    }, [isHovered]);

    return (
      <div
        className="menu-background-wrapper"
        style={{
          backgroundImage: 'url(' + img + ')',
          height: isHovered ? '100vh' : '0',
          animation,
          WebkitAnimation: animation,
        }}
        key={pageName}
      >
        {video && (
          <AlphaVideo
            mp4Src={videoMp4}
            webmSrc={videoWebm}
            shouldPlay={isHovered}
            style={{ height: isHovered ? '100vh' : '0' }}
          />
        )}
      </div>
    );
  },
);
