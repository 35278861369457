import React from 'react';
import iconArrowUp from '@src/img/icon-arrow-up.png';
import iconArrowDown from '@src/img/icon-arrow-down.png';

import './index.less';

type Props = {
  targetRef: any;
  className?: string;
  offset?: number;
};

export const ScrollArrowUp = ({
  targetRef,
  className = '',
  offset = 200,
}: Props) => {
  const handleScrollUp = (ref: any) => {
    const ele = ref.current;

    if (ele) {
      const target = ele.scrollTop - offset;
      ele.scrollTo({
        top: target > 0 ? target : 0,
        left: 0,
        behavior: 'smooth',
      });
    }
  };

  return (
    <div
      className={`scroll-arrow ${className}`}
      onClick={() => handleScrollUp(targetRef)}
    >
      <img src={iconArrowUp} />
    </div>
  );
};

export const ScrollArrowDown = ({
  targetRef,
  className = '',
  offset = 200,
}: Props) => {
  const handleScrollDown = (ref: any) => {
    const ele = ref.current;

    if (ele) {
      const target = ele.scrollTop + offset;
      ele.scrollTo({
        top: target,
        left: 0,
        behavior: 'smooth',
      });
    }
  };

  return (
    <div
      className={`scroll-arrow ${className}`}
      onClick={() => handleScrollDown(targetRef)}
    >
      <img src={iconArrowDown} />
    </div>
  );
};
