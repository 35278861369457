import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import allReadyImgAll from '@src/img/all-ready_all.png';
import allReadyImgReady from '@src/img/all-ready_ready.png';
import { LanguageAndIgSelect } from '@src/components/LanguageAndIgSelect/idnex';
import { HOME_PAGE_PATH } from '@src/constants/paths';
import { layoutContext } from '@src/contexts/layout';

import './index.less';

const AllReadyPage = () => {
  const { onChangeRoute } = useContext(layoutContext);
  const { i18n } = useTranslation();
  const history = useHistory();

  const onLanguageClick = async (lang: string) => {
    onChangeRoute && (await onChangeRoute(HOME_PAGE_PATH));
    
    i18n.changeLanguage(lang);
    history.push({ pathname: HOME_PAGE_PATH, search: `?lang=${lang}` });
  };

  return (
    <div className="all-ready-wrapper section-wrapper">
      <div className="all-ready-img-wrapper">
        <div className="all-ready-img-content">
          <div
            className="all"
            style={{
              backgroundImage: `url(${allReadyImgAll})`,
            }}
          />
          <div
            className="ready"
            style={{
              backgroundImage: `url(${allReadyImgReady})`,
            }}
          />
        </div>
      </div>
      <LanguageAndIgSelect onLanguageClick={onLanguageClick} />
    </div>
  );
};

export default AllReadyPage;
