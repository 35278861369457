import React, { useContext } from 'react';
import NavList from '@src/components/NavList';
import List from '@src/components/NavList/List';
import { allDataContext } from '@src/contexts/allData';
import { PAGE_NAME } from '@src/constants/common';

import './index.less';

const RegisterOfTransactionsPage = () => {
  const { files } = useContext(allDataContext);
  const fileList = files?.[PAGE_NAME.REGISTER_OF_TRANSACTION] || [];

  return (
    <div className="rot-wrapper section-wrapper">
      <NavList
        titleKey="register_of_transactions"
        navListWrapperClassName="nav-list-wrapper-rot"
        listItemDividerClassName="nav-list-divider-rot"
        listItemTitleClassName="nav-list-title-rot"
        content={<List items={fileList} />}
        itemCount={fileList.length}
      />
    </div>
  );
};

export default RegisterOfTransactionsPage;
