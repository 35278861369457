import React, { useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import { ScrollArrowDown, ScrollArrowUp } from '@src/components/ScrollArrow';
import { allDataContext } from '@src/contexts/allData';
import { LANGUAGE } from '@src/constants/common';
import { isNil, isNilOrEmpty, propOr } from '@src/utils/fp';
import popupFrameTop from '@src/img/pop_bkg_top.png';
import popupFrameBottom from '@src/img/pop_bkg_bot.png';
import { popupContent } from '..';

import './index.less';

export const PopupInformationSm = ({
  isPopupVisible,
  currentContent,
  customTitle,
  customContent,
  link,
}: {
  isPopupVisible: boolean;
  currentContent: string;
  customTitle?: string;
  customContent?: string;
  link?: string;
}) => {
  const { announcements, lastUpdatedEn, lastUpdatedCh } = useContext(
    allDataContext,
  );
  const announcementTextRef = useRef(null);
  const informationTextRef = useRef(null);
  const customTextRef = useRef(null);
  const { t, i18n } = useTranslation();

  return (
    <div
      className={`popup-wrapper-sm ${isPopupVisible ? `popup-visible` : ''}`}
    >
      <img className="popup-frame-img-sm" src={popupFrameTop} />
      <div className={`popup-content-wrapper-sm lang-${i18n.language}`}>
        {!isNil(customContent) && (
          <div className="popup-content-sm">
            <div className={`popup-title-sm lang-${i18n.language}`}>
              {customTitle || ''}
            </div>
            <ScrollArrowUp targetRef={customTextRef} />
            <div className={'popup-divider-sm'} />
            <div className={'popup-text-sm'} ref={customTextRef}>
              {parse(customContent || '')}
            </div>
            <div className={'popup-divider-sm'} />
            <ScrollArrowDown targetRef={customTextRef} />
            {link && (
              <div className="disclaimer_link">
                <a href={link} target="_blank">
                  {t('disclaimer_link')}
                </a>
              </div>
            )}
          </div>
        )}
        {currentContent === popupContent[0] && (
          <div className="popup-content-sm">
            <div className={`popup-title-sm lang-${i18n.language}`}>
              {t('announcement')}
            </div>
            <ScrollArrowUp
              targetRef={announcementTextRef}
              className={'scroll-arrow-sm'}
            />
            <div className={'popup-divider-sm'} />
            <div className={`popup-text-sm announcement lang-${i18n.language}`}>
              {/*  <p className="text-subtitle-sm">{t('announcement_subtitle_1')}</p>
              <p style={{ marginBottom: '22px' }}>
                {t('announcement_content_1')}
              </p>
              <p className="text-subtitle-sm">{t('announcement_subtitle_2')}</p>
              <p>{t('announcement_content_2a')}</p>
              <p>{t('announcement_content_2b')}</p>
              <p>{t('announcement_content_2c')}</p> */}
              {!isNilOrEmpty(propOr('', i18n.language, announcements)) && (
                <div
                  style={{ height: '90%', overflow: 'auto' }}
                  ref={announcementTextRef}
                >
                  {parse(propOr('', i18n.language, announcements) || '')}
                </div>
              )}
            </div>
            <div className={'popup-divider-sm'} />
            <ScrollArrowDown
              targetRef={announcementTextRef}
              className={'scroll-arrow-sm'}
            />
          </div>
        )}
        {currentContent === popupContent[1] && (
          <div className="popup-content-sm">
            <div className={`popup-title-sm lang-${i18n.language}`}>
              {t('information')}
            </div>
            <ScrollArrowUp
              targetRef={informationTextRef}
              className={'scroll-arrow-sm'}
            />
            <div className={'popup-divider-sm'} />
            <div
              className={`popup-text-sm info lang-${i18n.language}`}
              ref={informationTextRef}
            >
              <p>
                {t('information_content_1a')}
                <br />
                {t('information_content_1b')}
              </p>
              <br />
              <p>
                {t('information_content_2a')}
                <br />
                {t('information_content_2aa')}
                <br />
                {t('information_content_2b')}
              </p>
              <br />
              <p>{t('information_content_2c')}</p>
              <br />
              <p>
                {t('information_content_3a')}｜{t('information_content_3b')}｜
                {t('information_content_3c')}｜{t('information_content_3d')}｜
                {t('information_content_3e')}｜{t('information_content_4a')}｜
                {t('information_content_4b')}｜{t('information_content_4c')}｜
                {t('information_content_4d')}｜{t('information_content_5')}｜
                {t('information_content_9')}
              </p>
              <br />
              <p>{t('information_content_7')}</p>
            </div>
            <div className={'popup-divider-sm'} />
            <ScrollArrowDown
              targetRef={informationTextRef}
              className={'scroll-arrow-sm'}
            />
          </div>
        )}
        {currentContent === popupContent[2] && (
          <div className="popup-content-sm popup-content-copyright-sm">
            <div className={`popup-title-sm lang-${i18n.language}`}>
              {t('copyright_and_trade_mark')}
            </div>
            <div className={'popup-divider-sm'} />
            <div className={`popup-text-sm copyright-sm lang-${i18n.language}`}>
              <p>{t('copyright_and_trade_mark_content_1')}</p>
              <p>{t('copyright_and_trade_mark_content_2')}</p>
            </div>
          </div>
        )}
      </div>
      <img className="popup-frame-img-sm" src={popupFrameBottom} />
    </div>
  );
};
