import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import AllReadyPage from '@src/pages/AllReady';
import HomePage from '@src/pages/Home';
import ShowFlatPage from '@src/pages/ShowFlat';
import SalesOfficePage from '@src/pages/SalesOffice';
import SalesBrochurePage from '@src/pages/SalesBrochure';
import PriceListPage from '@src/pages/PriceList';
import SalesArrangementsPage from '@src/pages/SalesArrangements';
import RegisterOfTransactionsPage from '@src/pages/RegisterOfTransactions';
import DeedOfMutualCovenantPage from '@src/pages/DeedOfMutualCovenant';
import AerialPhotographPage from '@src/pages/AerialPhotograph';
import TenderDocumentPage from '@src/pages/TenderDocument';
import RegistrationPage from '@src/pages/Registration';
import BallotResultPage from '@src/pages/BallotResult';
import SalesBrochureForParkingSpacePage from '@src/pages/SalesBrochureForParkingSpace';
import { EntrancePage } from '@src/pages/Entrance';
import ParkingSpaceDocumentPage from '@src/pages/ParkingSpaceDocument';
import { Layout } from '@src/containers/Layout';
import {
  ROOT_PATH,
  HOME_PAGE_PATH,
  SHOW_FLAT_PAGE_PATH,
  SALES_OFFICE_PAGE_PATH,
  SALES_BROCHURE_PAGE_PATH,
  PRICE_LIST_PAGE_PATH,
  REGISTER_OF_TRANSACTION_PAGE_PATH,
  REGISTRATION_PAGE_PATH,
  SALES_ARRANGEMENTS_PAGE_PATH,
  SALES_BROCHURE_FOR_PARKING_SPACE_PAGE_PATH,
  TENDER_DOCUMENT_PAGE_PATH,
  AERIAL_PHOTOGRAPH_PAGE_PATH,
  BALLOT_RESULT_PAGE_PATH,
  DEED_OF_MUTUAL_COVENANT_PAGE_PATH,
  PARKING_SPACE_DOCUMENT_PAGE_PATH,
  ENTRANCE_PATH,
} from '@src/constants/paths';

const Routes = () => {
  return (
    <Router>
      <Layout>
        <Switch>
          <Route exact path={ROOT_PATH} component={EntrancePage} />
          <Route exact path={ENTRANCE_PATH} component={AllReadyPage} />
          <Route path={HOME_PAGE_PATH} component={HomePage} />
          <Route path={SHOW_FLAT_PAGE_PATH} component={ShowFlatPage} />
          <Route path={SALES_OFFICE_PAGE_PATH} component={SalesOfficePage} />
          <Route
            path={SALES_BROCHURE_PAGE_PATH}
            component={SalesBrochurePage}
          />
          <Route path={PRICE_LIST_PAGE_PATH} component={PriceListPage} />
          <Route
            path={SALES_ARRANGEMENTS_PAGE_PATH}
            component={SalesArrangementsPage}
          />
          <Route
            path={REGISTER_OF_TRANSACTION_PAGE_PATH}
            component={RegisterOfTransactionsPage}
          />
          <Route
            path={DEED_OF_MUTUAL_COVENANT_PAGE_PATH}
            component={DeedOfMutualCovenantPage}
          />
          <Route
            path={AERIAL_PHOTOGRAPH_PAGE_PATH}
            component={AerialPhotographPage}
          />
          <Route
            path={TENDER_DOCUMENT_PAGE_PATH}
            component={TenderDocumentPage}
          />
          <Route path={REGISTRATION_PAGE_PATH} component={RegistrationPage} />
          <Route path={BALLOT_RESULT_PAGE_PATH} component={BallotResultPage} />
          <Route
            path={SALES_BROCHURE_FOR_PARKING_SPACE_PAGE_PATH}
            component={SalesBrochureForParkingSpacePage}
          />
          <Route
            path={PARKING_SPACE_DOCUMENT_PAGE_PATH}
            component={ParkingSpaceDocumentPage}
          />
        </Switch>
      </Layout>
    </Router>
  );
};

export default Routes;
