import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import shkBrandLogo from '@src/img/shk-logo.png';

import './index.less';
import { allDataContext } from '@src/contexts/allData';
import { LANGUAGE } from '@src/constants/common';

export const FooterSM = ({
  onClickCopyright,
  onClickInformation,
}: {
  onClickCopyright: () => void;
  onClickInformation: () => void;
}) => {
  const { t, i18n } = useTranslation();
  const { lastUpdatedEn, lastUpdatedCh } = useContext(allDataContext);

  const onClickPhase = () => {
    window.open('https://www.theyohohub.com.hk', '_blank');
  };

  return (
    <div className={`footer-sm lang-${i18n.language}`}>
      <div className={`disclaimer-sm lang-${i18n.language}`}>
        {t('disclaimer')}
      </div>
      <p
        className={`information-wrapper-sm lang-${i18n.language}`}
        onClick={onClickInformation}
      >
        {t('information_a')}
        {t('information_b')}
      </p>
      <p className={`scope lang-${i18n.language}`}>{t('scope')}</p>
      <p className={`phase-name lang-${i18n.language}`}>
        {t('information_content_1a_i')}
        <br />
        {t('information_content_1a_ii')}
        &nbsp;
        {t('information_content_1b')}
      </p>
      <p className={`district lang-${i18n.language}`}>
        {t('information_content_2a')}
        <br />
        {t('information_content_2b')}
        <br />
        {t('hotline')}
      </p>
      <p className={`disclaimer-sm2 lang-${i18n.language}`}>
        {t('information_content_2d')}
      </p>
      <div className="brand-logo-wrapper-sm">
        <a href="https://www.shkp.com/" target={'_blank'} className="brand-logo">
        </a>
        <div className="phase-sm" onClick={onClickPhase}>
          <span>{t('phase_b')}</span>
        </div>
      </div>
      <p className={`company-name lang-${i18n.language}`}>
        {t('company_name_a')}
        <br />
        {t('company_name_b')}
        <br />
        {t('company_name_c')}
        <span
          style={{ textDecoration: 'underline' }}
          onClick={onClickCopyright}
        >
          {t('copyright_trade_mark')}
        </span>
      </p>
      <p className={`last-updated`}>
        {t('last_updated', {
          date: i18n.language === LANGUAGE.ENG ? lastUpdatedEn : lastUpdatedCh,
        })}
      </p>
    </div>
  );
};
