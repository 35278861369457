import React from 'react';
import { ProcessApiData } from '@src/types';

export const defaultAllData = {
  files: null,
  virtualTours: {},
  lastUpdated: '',
  announcements: {
    en: '',
    zh: '',
    sc: '',
  },
  lastUpdatedEn: '',
  lastUpdatedCh: '',
  isAnnouncementDisabled: false,
  isLoading: false
};

export const allDataContext = React.createContext<ProcessApiData>(
  defaultAllData,
);
