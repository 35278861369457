import React, { useContext, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LANGUAGE, Language, LANG_TEXT_MAP } from '@src/constants/common';
import instagramLogo from '@src/img/icon_instagram.png';

import './index.less';
import { AudioIcon } from '../AudioIcon';
import { allDataContext } from '@src/contexts/allData';

export const Footer = ({
  onClickCopyright,
  onClickInformation,
  onToggleSoundIcon,
  isSoundPlaying,
}: {
  onClickCopyright: () => void;
  onClickInformation: () => void;
  onToggleSoundIcon: () => void;
  isSoundPlaying: boolean;
}) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const { lastUpdatedEn, lastUpdatedCh } = useContext(allDataContext);

  const langOptions = useMemo(
    () => Object.keys(LANG_TEXT_MAP).filter((lang) => lang !== i18n.language),
    [i18n.language],
  );

  const handleChangeLang = (lang: Language) => {
    i18n.changeLanguage(lang);
    history.push({
      search: `?lang=${lang}`,
    });
  };

  const onClickPhase = () => {
    window.open('https://www.theyohohub.com.hk', '_blank');
  };

  const onClickInstagram = () => {
    window.open('https://www.instagram.com/theyohohub/', '_blank');
  };

  return (
    <div className={`footer ${i18n.language}`}>
      <div className="footer-left">
        <div className="brand-logo-wrapper">
          <a href="https://www.shkp.com/" target={'_blank'} className="brand-logo">
          </a>
          <div className="phase" onClick={onClickPhase}>
            {t('phase_b')}
          </div>
        </div>
        <div className="actions-wrapper">
          <div className="lang-wrapper">
            {langOptions.map((lang, index) => (
              <span key={lang}>
                <span
                  className="lang-option"
                  onClick={() => handleChangeLang(lang)}
                >
                  {LANG_TEXT_MAP[lang]}
                </span>
                {index !== langOptions.length - 1 && <span> | </span>}
              </span>
            ))}
          </div>
          <div className="sound-icon">
            <AudioIcon
              onToggleIcon={onToggleSoundIcon}
              isPlaying={isSoundPlaying}
            />
          </div>
          <img
            className="ig-icon"
            onClick={onClickInstagram}
            src={instagramLogo}
          />
        </div>
        <div className={`information-wrapper ${i18n.language}`} onClick={onClickInformation}>
          {t('information_a')}
          {t('information_b')}
        </div>
      </div>
      <div className="footer-right">
        <div className="disclaimer">{t('disclaimer')}</div>
        <div className="more-info">
          <span className="company-info">
            <span>{t('company_name')}</span>
            <span className="copyright-trade-mark" onClick={onClickCopyright}>
              {t('copyright_trade_mark')}
            </span>
          </span>
          <span className="update_date">
            {t('last_updated', {
              date:
                i18n.language === LANGUAGE.ENG ? lastUpdatedEn : lastUpdatedCh,
            })}
          </span>
        </div>
      </div>
    </div>
  );
};
