import React, { useContext } from 'react';
import { NavList } from '@src/components/NavList';
import { List } from '@src/components/NavList/List';
import { allDataContext } from '@src/contexts/allData';
import { PAGE_NAME } from '@src/constants/common';

import './index.less';

const TenderDocumentPage = () => {
  const { files } = useContext(allDataContext);
  const fileList = files?.[PAGE_NAME.TENDER_DOCUMENT] || [];

  return (
    <div className='td-wrapper section-wrapper'>
      <NavList
        titleKey="tender_document"
        navListWrapperClassName="nav-list-wrapper-td"
        listItemDividerClassName="nav-list-divider-td"
        listItemTitleClassName="nav-list-title-td"
        content={<List items={fileList} />}
        itemCount={fileList.length}
      />
    </div>
  );
};

export default TenderDocumentPage;
