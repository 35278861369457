import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { LANGUAGE } from '@src/constants/common';

import en from './locales/en';
import zh from './locales/zh';
import sc from './locales/sc';

i18n.use(initReactI18next).init({
  lng: LANGUAGE.ENG,
  fallbackLng: LANGUAGE.ENG,
  resources: {
    [LANGUAGE.ENG]: {
      translation: en,
    },
    [LANGUAGE.ZH]: {
      translation: zh,
    },
    [LANGUAGE.SC]: {
      translation: sc,
    },
  },
  interpolation: {
    escapeValue: false,
  },
  debug: process.env.REACT_APP_ENV === 'development',
});

export default i18n;
