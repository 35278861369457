import { VirtualTour } from '@src/types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';
import { propOr } from '@src/utils/fp';
import playBtn from '@src/img/btn_play.svg';

import './index.less';

export const SliderComp = ({
  titleKey,
  items,
  titleClassName,
  onItemClick,
}: {
  titleKey: string;
  items: VirtualTour[];
  titleClassName?: string;
  onItemClick: (item: VirtualTour) => void;
}) => {
  const { t, i18n } = useTranslation();

  const settings = {
    className: `slider-wrapper ${items.length ? '' : 'empty-list'}`,
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 820,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <div className="slider-title-wrapper">
        <div>
          <div className={`slider-title ${titleClassName || ''}`}>
            {t(titleKey)}
          </div>
          {!items.length && (
            <div className="empty-list-text">{t('coming_soon')}</div>
          )}
        </div>
      </div>
      {!!items.length && (
        <Slider {...settings}>
          {items.map((item) => {
            return item.thumbnail_path ? (
              <>
                <a
                  onClick={() => {
                    onItemClick(item);
                  }}
                  className="slider-item"
                >
                  <img src={item.thumbnail_path} className="thumbnail-img" />
                  <div className="play-btn">
                    <img src={playBtn} />
                  </div>
                </a>
                <div className="item-title">
                  {propOr('', i18n.language, item)}
                  {item[`sub_title_${i18n.language}`]
                    ? ` (${item[`sub_title_${i18n.language}`]})`
                    : ''}
                </div>
              </>
            ) : (
              <></>
            );
          })}
        </Slider>
      )}
    </>
  );
};
