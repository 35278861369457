import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { debounce } from 'debounce';
import { Menu } from '@src/components/Menu';
import { MenuBackgound } from '@src/components/MenuBackgound';
import { PAGE_NAME } from '../../constants/common';
import { allDataContext } from '@src/contexts/allData';
import { useMediaQuery } from '@src/hooks/useMediaQuery';
import { propOr } from '@src/utils/fp';

import './index.less';

const HomePage = () => {
  const { t, i18n } = useTranslation();
  const isMobile = useMediaQuery();
  const { announcements } = useContext(allDataContext);

  const [hoveringPageName, setHoveringPageName] = useState(PAGE_NAME.HOME);
  const announcementsLocale = propOr('', i18n.language, announcements);

  const handlePageNameHover = debounce((pageName: string) => {
    setHoveringPageName(pageName);
  }, 500);

  return (
    <div className="home-wrapper">
      <Menu
        onPageNameHover={handlePageNameHover}
      />
      {!isMobile && <MenuBackgound hoveringPageName={hoveringPageName} />}
    </div>
  );
};

export default HomePage;
