import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga4';
import App from './App';

import './index.less';
import './i18n';

ReactGA.initialize('G-QGWYZNB7HQ');

const root = document.getElementById('root');

if (root) {
  ReactDOM.render(<App />, root);
}
