import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ENTRANCE_PATH } from '@src/constants/paths';
import yohohubImg from '@src/img/logo_yohohub.png';
import yohohub2Img from '@src/img/logo_yohohub2.png';
import yohohub2EntraceImg from '@src/img/logo_yohohub2-entrace.png';
import bkgEntrance from '@src/img/bkg_entrance.jpg';
import bkgEntranceMobile from '@src/img/bkg_entrance_m.jpg';

import './index.less';
import { useMediaQuery } from '@src/hooks/useMediaQuery';
import { LANGUAGE } from '@src/constants/common';

export const EntrancePage = () => {
  const isMobile = useMediaQuery();
  const { i18n } = useTranslation();

  const onClickYohohub = () => {
    window.open('https://www.theyohohub.com.hk/');
  };

  return (
    <div className="entrance-wrapper">
      <div className="entrance-logo-container">

        <div className="yoho-phase-b-desc">
          <img className="yohohub-img" onClick={onClickYohohub} src={yohohubImg} />

          <p className="yoho-phase-desc-name">
            <span className="zh">The YOHO Hub</span>
            <span
              className={`${
                i18n.language === LANGUAGE.SC ? i18n.language : LANGUAGE.ZH
                }`}
            >
              發展項目的第B期
            </span>
            <br />
            <span className="zh">The YOHO Hub</span>
            <span
              className={`${
                i18n.language === LANGUAGE.SC ? i18n.language : LANGUAGE.ZH
                }`}
            >
              发展项目的第B期
            </span>
            <br />
            <span className="zh">Phase B of The YOHO Hub Development</span>
          </p>
          <p className="yoho-phase-desc-remark">
            <span
              className={`${
                i18n.language === LANGUAGE.SC ? i18n.language : LANGUAGE.ZH
                }`}
            >
              備註：第B期中住宅發展項目的第1座、第2座、第3座及第5座稱為「The YOHO Hub」
            </span>
            <br />
            <span
              className={`${
                i18n.language === LANGUAGE.SC ? i18n.language : LANGUAGE.ZH
                }`}
            >
              备注：第B期中住宅发展项目的第1座、第2座、第3座及第5座称为「The YOHO Hub」
            </span>
            <br />
            <span className="zh">
              Remarks: Tower 1, Tower 2, Tower 3 and Tower 5 of the residential development in Phase B are called “The YOHO Hub”
            </span>
          </p>
        </div>
        <div className="yoho-phase-c-desc">
          <Link to={{ pathname: ENTRANCE_PATH, search: `?lang=${i18n.language}` }}>
            <img className="yohohub2-img" src={yohohub2EntraceImg} />
          </Link>
          <p className="yoho-phase-desc-name">
            <span className="zh">The YOHO Hub</span>
            <span
              className={`${
                i18n.language === LANGUAGE.SC ? i18n.language : LANGUAGE.ZH
                }`}
            >
              发展项目的第C期
            </span>
            <br />
            <span className="zh">The YOHO Hub</span>
            <span
              className={`${
                i18n.language === LANGUAGE.SC ? i18n.language : LANGUAGE.ZH
                }`}
            >
              發展項目的第C期
            </span>
            <br />
            <span className="zh">Phase C of The YOHO Hub Development</span>
          </p>
          <p className="yoho-phase-desc-remark">
            <span
              className={`${
                i18n.language === LANGUAGE.SC ? i18n.language : LANGUAGE.ZH
                }`}
            >
              備註：第C期中住宅發展項目的第6座及第8座稱為「The YOHO Hub II」
            </span>
            <br />
            <span
              className={`${
                i18n.language === LANGUAGE.SC ? i18n.language : LANGUAGE.ZH
                }`}
            >
              备注：第C期中住宅发展项目的第6座及第8座称为「The YOHO Hub II」
            </span>
            <br />
            <span className="zh">
              Remarks: Tower 6 and Tower 8 of the residential development in Phase C are called “The YOHO Hub II”
            </span>
          </p>
        </div>



      </div>
      {isMobile && <img className="bkg-img-entrance" src={bkgEntranceMobile} />}
      <div className="remark">
        <span
          className={`${i18n.language !== LANGUAGE.ENG ? i18n.language : ''}`}
        >
          以上圖像並非於第B期或第C期實景拍攝。
        </span>
        <span
          className={`${i18n.language !== LANGUAGE.ENG ? i18n.language : ''}`}
        >
          以上图像并非于第B期或第C期实景拍摄。
        </span>
        <span className="lang-sc">
          The above images are not taken from Phase B or Phase C.
        </span>
      </div>
    </div>

  );
};
