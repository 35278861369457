import React, { useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  AERIAL_PHOTOGRAPH_PAGE_PATH,
  BALLOT_RESULT_PAGE_PATH,
  DEED_OF_MUTUAL_COVENANT_PAGE_PATH,
  PARKING_SPACE_DOCUMENT_PAGE_PATH,
  PRICE_LIST_PAGE_PATH,
  REGISTER_OF_TRANSACTION_PAGE_PATH,
  REGISTRATION_PAGE_PATH,
  SALES_ARRANGEMENTS_PAGE_PATH,
  SALES_BROCHURE_FOR_PARKING_SPACE_PAGE_PATH,
  SALES_BROCHURE_PAGE_PATH,
  SALES_OFFICE_PAGE_PATH,
  SHOW_FLAT_PAGE_PATH,
  TENDER_DOCUMENT_PAGE_PATH,
} from '@src/constants/paths';
import { PAGE_NAME } from '@src/constants/common';
import { useMediaQuery } from '@src/hooks/useMediaQuery';
import { LanguageAndIgSelect } from '../LanguageAndIgSelect/idnex';
import virtualTour from '@src/img/360_virtual_tour.png';
import hintArrow from '@src/img/icon-hint-arrow.png';

import './index.less';
import { layoutContext } from '@src/contexts/layout';

type Props = {
  selectedPagePath?: string;
  onPageNameHover?: (pageName: string) => void;
  onPageNameUnHover?: () => void;
  isDrawer?: boolean;
};

export const Menu = ({
  selectedPagePath = '',
  onPageNameHover,
  isDrawer = false,
}: Props) => {
  const { onChangeRoute } = useContext(layoutContext);
  const { t, i18n } = useTranslation();
  const isMobile = useMediaQuery();
  const history = useHistory();

  const onItemHover = (pageName: string) => {
    onPageNameHover && onPageNameHover(pageName);
  };

  const onLanguageClick = (lang: string) => {
    i18n.changeLanguage(lang);
  };

  const onLinkClick = async (pathName: string) => {
    !isMobile && onChangeRoute && (await onChangeRoute(pathName));

    history.push({ pathname: pathName, search: `?lang=${i18n.language}` });
  };

  const itemConfigs = [
    {
      pageName: PAGE_NAME.SHOW_FLAT,
      link: SHOW_FLAT_PAGE_PATH,
      textKey: 'show_flat',
      textColor: '#ff47d9',
      className: 'menu-item-show-flat',
    },
    {
      pageName: PAGE_NAME.SALES_OFFICE,
      link: SALES_OFFICE_PAGE_PATH,
      textKey: 'sales_office',
      textColor: '#009894',
      className: 'menu-item-sales-office',
    },
    {
      pageName: PAGE_NAME.SALES_BROCHURE,
      link: SALES_BROCHURE_PAGE_PATH,
      textKey: 'sales_brochure',
      textColor: '#ff803e',
      className: 'menu-item-sales-brochure',
    },
    {
      pageName: PAGE_NAME.PRICE_LIST,
      link: PRICE_LIST_PAGE_PATH,
      textKey: 'price_list',
      textColor: '#ad2078',
      className: 'menu-item-price-list',
    },
    {
      pageName: PAGE_NAME.SALES_ARRANGEMENTS,
      link: SALES_ARRANGEMENTS_PAGE_PATH,
      textKey: 'sales_arrangements',
      textColor: '#c0ff66',
      className: 'menu-item-sales-rrangements',
    },
    {
      pageName: PAGE_NAME.REGISTER_OF_TRANSACTION,
      link: REGISTER_OF_TRANSACTION_PAGE_PATH,
      textKey: 'register_of_transactions',
      textColor: '#ffcd58',
      className: 'menu-item-register-of-transactions',
    },
    {
      pageName: PAGE_NAME.DEED_OF_MUTUAL_COVENANT,
      link: DEED_OF_MUTUAL_COVENANT_PAGE_PATH,
      textKey: 'deed_of_mutual_covenant',
      textColor: '#73ff9f',
      className: 'menu-item-deed-of-mutual-covenant',
    },
    {
      pageName: PAGE_NAME.AERIAL_PHOTOGRAPH,
      link: AERIAL_PHOTOGRAPH_PAGE_PATH,
      textKey: 'aerial_photograph',
      textColor: '#80a2ff',
      className: 'menu-item-aerial-photograph',
    },
    {
      pageName: PAGE_NAME.TENDER_DOCUMENT,
      link: TENDER_DOCUMENT_PAGE_PATH,
      textKey: 'tender_document',
      textColor: '#ff803e',
      className: 'menu-item-tender-document',
    },
    {
      pageName: PAGE_NAME.REGISTRATION,
      link: REGISTRATION_PAGE_PATH,
      textKey: 'registration',
      textColor: '#d8ff30',
      className: 'menu-item-registration',
    },
    {
      pageName: PAGE_NAME.BALLOT_RESULT,
      link: BALLOT_RESULT_PAGE_PATH,
      textKey: 'ballot_result',
      textColor: '#cf483b',
      className: 'menu-item-ballot-result',
    },
    {
      pageName: PAGE_NAME.SALES_BROCHURE_FOR_PARKING_SPACE,
      link: SALES_BROCHURE_FOR_PARKING_SPACE_PAGE_PATH,
      textKey: 'sales_brochure_for_parking_space',
      textColor: '#ecb672',
      className: 'menu-item-sales-brochure-for-parking-space',
    },
    {
      pageName: PAGE_NAME.PARKING_SPACE_DOCUMENT,
      link: PARKING_SPACE_DOCUMENT_PAGE_PATH,
      textKey: 'parking_space_document',
      textColor: '#64ff9e',
      className: 'menu-item-parking-space-document',
    },
  ];

  return (
    <>
      <div className={`menu-wrapper ${isDrawer ? 'drawer' : ''}`}>
        <div className={`link-list-wrapper ${isDrawer ? 'drawer' : ''}`}>
          {isDrawer ? (
            <div className={`menu-upper drawer lang-${i18n.language}`}>
              <img
                src={virtualTour}
                style={{
                  position: 'absolute',
                  width: ' 230px',
                  right: '121px',
                  top: '-3px',
                  zIndex: '10',
                }}
              />
              <span
                className={`menu-item-span-show-flat lang-${i18n.language} isDrawer`}
                style={{
                  position: 'absolute',
                  right: '0',
                }}
              >
                <div
                   onClick={() => onLinkClick(itemConfigs[0].link)}
                  className={`menu-item ${itemConfigs[0].className} lang-${
                    i18n.language
                  } ${
                    selectedPagePath === itemConfigs[0].link
                      ? 'menu-item-selected'
                      : ''
                  }`}
                  onMouseOver={() => {
                    onItemHover(itemConfigs[0].pageName);
                  }}
                  {...(selectedPagePath === itemConfigs[0].link && {
                    style: { color: itemConfigs[0].textColor },
                  })}
                >
                  {t(itemConfigs[0].textKey)}
                </div>
              </span>
              <br />
              <span
                style={{
                  position: 'absolute',
                  right: '0',
                  top: '28px',
                }}
                className={`menu-item-span-sales-office lang-${i18n.language} isDrawer`}
              >
                <div
                  onClick={() => onLinkClick(itemConfigs[1].link)}
                  className={`menu-item ${itemConfigs[1].className} lang-${
                    i18n.language
                  } ${
                    selectedPagePath === itemConfigs[1].link
                      ? 'menu-item-selected'
                      : ''
                  }`}
                  onMouseOver={() => {
                    onItemHover(itemConfigs[1].pageName);
                  }}
                  {...(selectedPagePath === itemConfigs[1].link && {
                    style: { color: itemConfigs[1].textColor },
                  })}
                >
                  {t(itemConfigs[1].textKey)}
                </div>
              </span>
            </div>
          ) : (
            <div
              className={`menu-upper lang-${i18n.language} ${
                isDrawer ? 'drawer' : ''
              }`}
            >
              <img
                src={virtualTour}
                style={{ position: 'absolute', width: ' 390px', zIndex: '10' }}
              />
              <span
                className={`menu-item-span-show-flat lang-${i18n.language}`}
                style={{ paddingLeft: '410px', position: 'absolute' }}
              >
                <div
                   onClick={() => onLinkClick(itemConfigs[0].link)}
                  className={`menu-item ${itemConfigs[0].className} lang-${
                    i18n.language
                  } ${
                    selectedPagePath === itemConfigs[0].link
                      ? 'menu-item-selected'
                      : ''
                  }`}
                  onMouseOver={() => {
                    onItemHover(itemConfigs[0].pageName);
                  }}
                >
                  {t(itemConfigs[0].textKey)}
                </div>
              </span>
              <br />
              <span
                style={{
                  paddingLeft: '340px',
                  position: 'absolute',
                  top: '41px',
                }}
                className={`menu-item-span-sales-office lang-${i18n.language}`}
              >
                <div
                  onClick={() => onLinkClick(itemConfigs[1].link)}
                  className={`menu-item ${itemConfigs[1].className} lang-${
                    i18n.language
                  } ${
                    selectedPagePath === itemConfigs[1].link
                      ? 'menu-item-selected'
                      : ''
                  }`}
                  onMouseOver={() => {
                    onItemHover(itemConfigs[1].pageName);
                  }}
                >
                  {t(itemConfigs[1].textKey)}
                </div>
              </span>
            </div>
          )}
          <div className={`menu-lower ${isDrawer ? 'drawer' : ''}`}>
            {itemConfigs
              .slice(2)
              .map(({ pageName, link, textKey, textColor, className }) => (
                <div
                  className={`link-wrapper ${isDrawer ? 'drawer' : ''}`}
                  key={pageName}
                >
                  <div
                    onClick={() => onLinkClick(link)}
                    className={`menu-item ${className} lang-${i18n.language} ${
                      selectedPagePath === link ? 'menu-item-selected' : ''
                    }`}
                    onMouseOver={() => {
                      onItemHover(pageName);
                    }}
                    {...(isDrawer &&
                      selectedPagePath === link && {
                        style: { color: textColor },
                      })}
                  >
                    {t(textKey)}
                  </div>
                </div>
              ))}
          </div>
        </div>
        {!isDrawer && !isMobile && (
          <div className="scroll-hint">
            <img className="icon-hint-arrow" src={hintArrow} />
            {t('scroll_hint')}
          </div>
        )}
        {isDrawer && isMobile && (
          <LanguageAndIgSelect inDrawer onLanguageClick={onLanguageClick} />
        )}
      </div>
    </>
  );
};
