import React, { useEffect, useRef } from 'react';

import './index.less';

type Props = {
  mp4Src: string;
  webmSrc: string;
  style?: React.CSSProperties;
  shouldPlay: boolean;
};

export const AlphaVideo = ({
  mp4Src,
  webmSrc,
  style = {},
  shouldPlay,
}: Props) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    if (shouldPlay) {
      videoRef && videoRef.current && videoRef.current.play();
    } else {
      videoRef && videoRef.current && videoRef.current.pause();
    }
  }, [shouldPlay]);

  return (
    <div className="alpha-video-wrapper" style={style}>
      <video
        className="alpha-video"
        width="100%"
        height="100%"
        autoPlay
        loop
        muted
        playsInline
        ref={videoRef}
      >
        <source src={mp4Src} type='video/mp4; codecs="hvc1"' />
        <source src={webmSrc} type="video/webm" />
      </video>
    </div>
  );
};

export default AlphaVideo;
