import React from 'react';
import { LANG_TEXT_MAP } from '@src/constants/common';
import { useMediaQuery } from '@src/hooks/useMediaQuery';
import instagramLogo from '@src/img/icon_instagram.png';

import './index.less';

export const LanguageAndIgSelect = ({
  onLanguageClick,
  inDrawer = false,
}: {
  onLanguageClick: (lang: string) => void;
  inDrawer?: boolean;
}) => {
  const isMobile = useMediaQuery();
  const langOptions = Object.keys(LANG_TEXT_MAP);

  return (
    <div className={`lang-ig-select-wrapper sc ${inDrawer ? 'in-drawer' : ''}`}>
      {langOptions.map((lang) => (
        <span onClick={() => onLanguageClick(lang)} key={lang}>
          {LANG_TEXT_MAP[lang]}
        </span>
      ))}
      {isMobile && <img className="icon-ig" src={instagramLogo} />}
    </div>
  );
};
