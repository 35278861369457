import React, { useContext } from 'react';
import { NavList } from '@src/components/NavList';
import { List } from '@src/components/NavList/List';
import { allDataContext } from '@src/contexts/allData';
import { PAGE_NAME } from '@src/constants/common';
import bkg from '@src/img/bkg_sales_brochure.jpg';
import bkgMobile from '@src/img/bkg_sales_brochure_m.jpg';

import './index.less';

const SalesBrochurePage = () => {
  const { files } = useContext(allDataContext);
  const fileList = files?.[PAGE_NAME.SALES_BROCHURE] || [];

  return (
    <div className="sb-wrapper section-wrapper">
      <NavList
        titleKey="sales_brochure"
        navListWrapperClassName="nav-list-wrapper-sb"
        listItemDividerClassName="nav-list-divider-sb"
        listItemTitleClassName="nav-list-title-sb"
        content={<List items={fileList} />}
        itemCount={fileList.length}
      />
    </div>
  );
};

export default SalesBrochurePage;
