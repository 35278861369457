import React, { useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import { ScrollArrowDown, ScrollArrowUp } from '@src/components/ScrollArrow';
import { LANGUAGE } from '@src/constants/common';
import { allDataContext } from '@src/contexts/allData';
import { isNil, isNilOrEmpty, propOr } from '@src/utils/fp';
import popupFrame from '@src/img/popup_frame.png';

import './index.less';

export const popupContent = [
  'announcement',
  'information',
  'copyRightAndTradeMark',
];

export const PopupInformation = ({
  currentContent,
  customTitle,
  customContent,
  link,
}: {
  currentContent: string;
  customTitle?: string;
  customContent?: string;
  link?: string;
}) => {
  const { announcements, lastUpdatedEn, lastUpdatedCh } = useContext(
    allDataContext,
  );
  const announcementTextRef = useRef(null);
  const informationTextRef = useRef(null);
  const customTextRef = useRef(null);
  const { t, i18n } = useTranslation();

  return (
    <div className="popup-wrapper">
      <div className={`popup-content-wrapper lang-${i18n.language}`}>
        <img className="popup-frame-img" src={popupFrame} />
        {!isNil(customContent) && (
          <div className="popup-content">
            <div className={`popup-title lang-${i18n.language}`}>
              {customTitle || ''}
            </div>
            <ScrollArrowUp targetRef={customTextRef} />
            <div className={'popup-divider'} />
            <div className={'popup-text'} ref={customTextRef}>
              {/* <iframe
                srcDoc={customContent}
                style={{
                  width: '100%',
                  height: '96%',
                  border: 'unset',
                }}
              /> */}
              {parse(customContent || '')}
            </div>
            <div className={'popup-divider'} />
            <ScrollArrowDown targetRef={customTextRef} />
            {link && (
              <div className="disclaimer_link">
                <a href={link} target="_blank">
                  {t('disclaimer_link')}
                </a>
              </div>
            )}
          </div>
        )}
        {currentContent === popupContent[0] && (
          <div className="popup-content">
            <div className={`popup-title lang-${i18n.language}`}>
              {t('announcement')}
            </div>
            <ScrollArrowUp targetRef={announcementTextRef} />
            <div className={'popup-divider'} />
            <div className={'popup-text announcement'}>
              {/*  <p className="text-subtitle">{t('announcement_subtitle_1')}</p>
              <p style={{ marginBottom: '22px' }}>
                {t('announcement_content_1')}
              </p>
              <p className="text-subtitle">{t('announcement_subtitle_2')}</p>
              <p>{t('announcement_content_2a')}</p>
              <p>{t('announcement_content_2b')}</p>
              <p>{t('announcement_content_2c')}</p> */}
              {!isNilOrEmpty(propOr('', i18n.language, announcements)) && (
                <div
                  style={{ height: '90%', overflow: 'auto' }}
                  ref={announcementTextRef}
                >
                  {parse(propOr('', i18n.language, announcements) || '')}
                </div>
              )}
            </div>
            <div className={'popup-divider'} />
            <ScrollArrowDown targetRef={announcementTextRef} />
          </div>
        )}
        {currentContent === popupContent[1] && (
          <div className="popup-content">
            <div className={`popup-title lang-${i18n.language}`}>
              {t('information')}
            </div>
            <ScrollArrowUp targetRef={informationTextRef} />
            <div className={'popup-divider'} />
            <div className="popup-text info" ref={informationTextRef}>
              <p>
                {t('information_content_1a')}
                <br />
                {t('information_content_1b')}
              </p>
              <br />
              <p>
                {t('information_content_2a')}
                <br />
                {t('information_content_2aa')}
                <br />
                {t('information_content_2b')}
              </p>
              <br />
              <p>{t('information_content_2c')}</p>
              <br />
              <p>
                {t('information_content_3a')}｜{t('information_content_3b')}｜
                {t('information_content_3c')}｜{t('information_content_3d')}｜
                {t('information_content_3e')}｜{t('information_content_4a')}｜
                {t('information_content_4b')}｜{t('information_content_4c')}｜
                {t('information_content_4d')}｜{t('information_content_5')}｜
                {t('information_content_9')}
              </p>
              <br />
              <p>{t('information_content_7')}</p>
            </div>
            <div className={'popup-divider'} />
            <ScrollArrowDown targetRef={informationTextRef} />
          </div>
        )}
        {currentContent === popupContent[2] && (
          <div className="popup-content popup-content-copyright">
            <div className={`popup-title lang-${i18n.language}`}>
              {t('copyright_and_trade_mark')}
            </div>
            <div className={'popup-divider'} />
            <div className="popup-text copyright">
              <p>{t('copyright_and_trade_mark_content_1')}</p>
              <br />
              <p>{t('copyright_and_trade_mark_content_2')}</p>
            </div>
            <div className={'popup-divider'} />
          </div>
        )}
      </div>
    </div>
  );
};
