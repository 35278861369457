export const LANGUAGE = {
  ENG: 'en',
  ZH: 'zh',
  SC: 'sc',
};

export type Language = typeof LANGUAGE[keyof typeof LANGUAGE];

export const LANG_TEXT_MAP = {
  [LANGUAGE.ENG]: 'ENG',
  [LANGUAGE.ZH]: '繁',
  [LANGUAGE.SC]: '简',
};

export const PAGE_NAME = {
  HOME: 'home',
  SHOW_FLAT: 'showFlat',
  SALES_OFFICE: 'salesOffice',
  SALES_BROCHURE: 'salesBrochure',
  PRICE_LIST: 'priceList',
  SALES_ARRANGEMENTS: 'salesArrangements',
  REGISTER_OF_TRANSACTION: 'registerOfTransactions',
  DEED_OF_MUTUAL_COVENANT: 'deedOfMutualCovenant',
  AERIAL_PHOTOGRAPH: 'aerialPhotograph',
  TENDER_DOCUMENT: 'tenderDocument',
  REGISTRATION: 'registration',
  BALLOT_RESULT: 'ballotResult',
  SALES_BROCHURE_FOR_PARKING_SPACE: 'salesBrochureForParkingSpace',
  PARKING_SPACE_DOCUMENT: 'parkingSpaceDocument',
};

export type PageName = typeof PAGE_NAME[keyof typeof PAGE_NAME];
