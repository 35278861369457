import React from 'react';
import { useTranslation } from 'react-i18next';
import { propOr } from '@src/utils/fp';
import { SectionFile } from '@src/types';

type Props = {
  items?: SectionFile[];
  itemWrapperClassName?: string;
  onItemClick?: (item: SectionFile) => void;
  showLink?: boolean;
};

export const List = ({
  items = [],
  itemWrapperClassName = '',
  onItemClick,
  showLink = true,
}: Props) => {
  const { t, i18n } = useTranslation();

  return (
    <div className="list-wrapper">
      {items.map((item, index) => (
        <div
          className={`list-item-wrapper ${itemWrapperClassName}`}
          key={index}
          {...(onItemClick && {
            style: { cursor: 'pointer' },
            onClick: () => onItemClick(item),
          })}
        >
          <div className={`list-file-name ${showLink ? '' : 'width-90'}`}>
            {propOr('', i18n.language, item)}
            {item[`sub_title_${i18n.language}`]
              ? ` (${item[`sub_title_${i18n.language}`]})`
              : ''}
          </div>
          {showLink && item.link && (
            <div className="list-file-link-container">
              <a className="list-file-link" href={item.link} target="_blank">
                {t('download')}
              </a>
            </div>
          )}
        </div>
      ))}
      {!items.length && <p>{t('coming_soon')}</p>}
    </div>
  );
};

export default List;
