import React, { useContext, useEffect, useState } from 'react';
import Slider from 'react-slick';
import { useMediaQuery } from '@src/hooks/useMediaQuery';
import { useTranslation } from 'react-i18next';
import { List } from '@src/components/NavList/List';
import { PopupInformation } from '@src/components/PopupInformation';
import { PopupInformationSm } from '@src/components/PopupInformation/PopupInformationSm';
import { NavList } from '@src/components/NavList';
import { allDataContext } from '@src/contexts/allData';
import { PAGE_NAME } from '@src/constants/common';
import bkg from '@src/img/bkg_sales_office.jpg';
import iconClose from '@src/img/icon-close.png';
import { VirtualTour } from '@src/types';

import './index.less';
import { SliderComp } from '@src/components/SliderComp';

const SalesOfficePage = () => {
  const isMobile = useMediaQuery();
  const { t, i18n } = useTranslation();
  const { virtualTours } = useContext(allDataContext);
  const details = virtualTours[PAGE_NAME.SALES_OFFICE] || [];
  const [selectedItem, setSelectedItem] = useState<VirtualTour | null>(null);
  const [popupVisible, setPopupVisible] = useState(false);

  const handleClickItem = (item: VirtualTour) => {
    setSelectedItem(item);

    if (item[`disclaimer_${i18n.language}`]) {
      setPopupVisible(true);
    }
  };

  const handleClosePopup = () => {
    setPopupVisible(false);
  };

  // useEffect(() => {
  //   if (!selectedItem && details[0]) {
  //     setSelectedItem(details[0]);
  //   }
  // }, [details]);

  return (
    <>
      {popupVisible && !isMobile && (
        <PopupInformation
          currentContent=""
          customTitle={t('virtual_tour_disclaimer_title')}
          customContent={selectedItem?.[`disclaimer_${i18n.language}`] || ''}
          link={selectedItem?.link || ''}
        />
      )}
      {popupVisible && isMobile && (
        <PopupInformationSm
          isPopupVisible={popupVisible}
          currentContent={''}
          customTitle={t('virtual_tour_disclaimer_title')}
          customContent={selectedItem?.[`disclaimer_${i18n.language}`] || ''}
          link={selectedItem?.link || ''}
        />
      )}
      {popupVisible && isMobile && <div className="popup-mask" />}
      {popupVisible && (
        <img
          src={iconClose}
          className={`popup-close-icon custom`}
          onClick={handleClosePopup}
        />
      )}
      <div className="so-wrapper section-wrapper">
        <div className="so-content">
          <SliderComp
            titleKey="sales_office"
            items={details}
            titleClassName={'slider-title-so'}
            onItemClick={handleClickItem}
          />
          {/* <NavList
            titleKey="sales_office"
            navListWrapperClassName={`nav-list-wrapper-so ${
              popupVisible ? `popup-visible` : ''
            }`}
            listItemDividerClassName="nav-list-divider-so"
            listItemTitleClassName="nav-list-title-so"
            content={
              <List
                items={details}
                onItemClick={handleClickItem}
                showLink={false}
              />
            }
            itemCount={details.length}
          />
          {selectedItem?.link && (
            <div className="iframe-wrapper-so">
              <iframe className="iframe-so" src={selectedItem.link}></iframe>
            </div>
          )} */}
        </div>
      </div>
    </>
  );
};

export default SalesOfficePage;
