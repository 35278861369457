import React, { useContext } from 'react';
import { NavList } from '@src/components/NavList';
import { List } from '@src/components/NavList/List';
import { allDataContext } from '@src/contexts/allData';
import { PAGE_NAME } from '@src/constants/common';

import './index.less';

const DeedOfMutualCovenantPage = () => {
  const { files } = useContext(allDataContext);
  const fileList = files?.[PAGE_NAME.DEED_OF_MUTUAL_COVENANT] || [];

  return (
    <div className='domc-wrapper section-wrapper'>
      <NavList
        titleKey="deed_of_mutual_covenant"
        navListWrapperClassName="nav-list-wrapper-domc"
        listItemDividerClassName="nav-list-divider-domc"
        listItemTitleClassName="nav-list-title-domc"
        content={<List items={fileList} />}
        itemCount={fileList.length}
      />
    </div>
  );
};

export default DeedOfMutualCovenantPage;
