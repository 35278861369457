import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

export function apiClient(options: AxiosRequestConfig = {}): AxiosInstance {
  const instance = axios.create({
    baseURL: '',
    timeout: 60000,
    ...options,
  });

  return instance;
}
