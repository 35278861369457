import bkgGetReadyMobile from '@src/img/bkg_all_ready_m.jpg';
import bkgHomeMobile from '@src/img/bkg_home_m.jpg';
import bkgShowFlatMobile from '@src/img/bkg_showflat_m.jpg';
import bkgSalesOfficeMobile from '@src/img/bkg_sales_office_m.jpg';
import bkgSalesBrochureMobile from '@src/img/bkg_sales_brochure_m.jpg';
import bkgPriceListMobile from '@src/img/bkg_price_list_m.jpg';
import bkgSalesArrangementsMobile from '@src/img/bkg_sales_arrangements_m.jpg';
import bkgRegisterOfTransactionsMobile from '@src/img/bkg_register_of_transactions_m.jpg';
import bkgDeedOfMutualCovenantMobile from '@src/img/bkg_deed_of_mutual_covenant_m.jpg';
import bkgAerialPhotographMobile from '@src/img/bkg_aerial_photograph_m.jpg';
import bkgTenderDocumentMobile from '@src/img/bkg_tender_document_m.jpg';
import bkgRegistrationMobile from '@src/img/bkg_registration_m.jpg';
import bkgBallotResultMobile from '@src/img/bkg_ballot_result_m.jpg';
import bkgSalesBrochureForParkingSpaceMobile from '@src/img/bkg_sales_brochure_for_parking_space_m.jpg';
import bkgParkingSpaceDocumentMobile from '@src/img/bkg_parking_space_document_m.jpg';

import bkgGetReady from '@src/img/bkg_all_ready.jpg';
import bkgHome from '@src/img/bkg_home.jpg';
import bkgShowFlat from '@src/img/bkg_showflat.jpg';
import bkgSalesOffice from '@src/img/bkg_sales_office.jpg';
import bkgSalesBrochure from '@src/img/bkg_sales_brochure.jpg';
import bkgPriceList from '@src/img/bkg_price_list.jpg';
import bkgSalesArrangements from '@src/img/bkg_sales_arrangements.jpg';
import bkgRegisterOfTransactions from '@src/img/bkg_register_of_transactions.jpg';
import bkgDeedOfMutualCovenant from '@src/img/bkg_deed_of_mutual_covenant.jpg';
import bkgAerialPhotograph from '@src/img/bkg_aerial_photograph.jpg';
import bkgTenderDocument from '@src/img/bkg_tender_document.jpg';
import bkgRegistration from '@src/img/bkg_registration.jpg';
import bkgBallotResult from '@src/img/bkg_ballot_result.jpg';
import bkgSalesBrochureForParkingSpace from '@src/img/bkg_sales_brochure_for_parking_space.jpg';
import bkgParkingSpaceDocument from '@src/img/bkg_parking_space_document.jpg';

import {
  ROOT_PATH,
  ENTRANCE_PATH,
  HOME_PAGE_PATH,
  SHOW_FLAT_PAGE_PATH,
  SALES_OFFICE_PAGE_PATH,
  SALES_BROCHURE_PAGE_PATH,
  PRICE_LIST_PAGE_PATH,
  SALES_ARRANGEMENTS_PAGE_PATH,
  REGISTER_OF_TRANSACTION_PAGE_PATH,
  DEED_OF_MUTUAL_COVENANT_PAGE_PATH,
  AERIAL_PHOTOGRAPH_PAGE_PATH,
  TENDER_DOCUMENT_PAGE_PATH,
  REGISTRATION_PAGE_PATH,
  BALLOT_RESULT_PAGE_PATH,
  SALES_BROCHURE_FOR_PARKING_SPACE_PAGE_PATH,
  PARKING_SPACE_DOCUMENT_PAGE_PATH,
} from './paths';

export const pathBkgImgMap = {
  [ROOT_PATH]: {
    default: '',
    mobile: '',
    color: '',
  },
  [ENTRANCE_PATH]: {
    default: bkgGetReady,
    mobile: bkgGetReadyMobile,
    color: '#2C416B',
  },
  [HOME_PAGE_PATH]: {
    default: bkgHome,
    mobile: bkgHomeMobile,
    color: '#4D2057',
  },
  [SHOW_FLAT_PAGE_PATH]: {
    default: bkgShowFlat,
    mobile: bkgShowFlatMobile,
    color: '#3C7277',
  },
  [SALES_OFFICE_PAGE_PATH]: {
    default: bkgSalesOffice,
    mobile: bkgSalesOfficeMobile,
    color: '#B5A234',
    position: 'right',
  },
  [SALES_BROCHURE_PAGE_PATH]: {
    default: bkgSalesBrochure,
    mobile: bkgSalesBrochureMobile,
    color: '#803A80',
  },
  [PRICE_LIST_PAGE_PATH]: {
    default: bkgPriceList,
    mobile: bkgPriceListMobile,
    color: '#D8BB1D',
  },
  [SALES_ARRANGEMENTS_PAGE_PATH]: {
    default: bkgSalesArrangements,
    mobile: bkgSalesArrangementsMobile,
    color: '#adc8d3',
    gradient: ['#7A8A8E90', '#BAD3D850'],
    position: 'left',
  },
  [REGISTER_OF_TRANSACTION_PAGE_PATH]: {
    default: bkgRegisterOfTransactions,
    mobile: bkgRegisterOfTransactionsMobile,
    color: '#268068',
    gradient: ['#31617490', '#26806850'],
    position: 'left',
  },
  [DEED_OF_MUTUAL_COVENANT_PAGE_PATH]: {
    default: bkgDeedOfMutualCovenant,
    mobile: bkgDeedOfMutualCovenantMobile,
    color: '#C0DADE',
    gradient: ['#7A8A8E90', '#BAD3D850'],
  },
  [AERIAL_PHOTOGRAPH_PAGE_PATH]: {
    default: bkgAerialPhotograph,
    mobile: bkgAerialPhotographMobile,
    color: '#482864',
    position: 'right',
  },
  [TENDER_DOCUMENT_PAGE_PATH]: {
    default: bkgTenderDocument,
    mobile: bkgTenderDocumentMobile,
    color: '#055964',
  },
  [REGISTRATION_PAGE_PATH]: {
    default: bkgRegistration,
    mobile: bkgRegistrationMobile,
    color: '#C0DADE',
    gradient: ['#7A8A8E90', '#BAD3D850'],
  },
  [BALLOT_RESULT_PAGE_PATH]: {
    default: bkgBallotResult,
    mobile: bkgBallotResultMobile,
    color: '#AF9B33',
  },
  [SALES_BROCHURE_FOR_PARKING_SPACE_PAGE_PATH]: {
    default: bkgSalesBrochureForParkingSpace,
    mobile: bkgSalesBrochureForParkingSpaceMobile,
    color: '#268068',
    gradient: ['#31617490', '#26806850'],
  },
  [PARKING_SPACE_DOCUMENT_PAGE_PATH]: {
    default: bkgParkingSpaceDocument,
    mobile: bkgParkingSpaceDocumentMobile,
    color: '#803A80',
  },
};
