export const ROOT_PATH = '/';
export const ENTRANCE_PATH = '/entrance';
export const HOME_PAGE_PATH = '/home';
export const SHOW_FLAT_PAGE_PATH = '/showFlat';
export const SALES_OFFICE_PAGE_PATH = '/clubHouse';
export const SALES_BROCHURE_PAGE_PATH = '/salesBrochure';
export const PRICE_LIST_PAGE_PATH = '/priceList';
export const SALES_ARRANGEMENTS_PAGE_PATH = '/salesArrangements';
export const REGISTER_OF_TRANSACTION_PAGE_PATH = '/registerOfTransactions';
export const DEED_OF_MUTUAL_COVENANT_PAGE_PATH = '/deedOfMutualCovenant';
export const AERIAL_PHOTOGRAPH_PAGE_PATH = '/aerialPhotograph';
export const TENDER_DOCUMENT_PAGE_PATH = '/tenderDocument';
export const REGISTRATION_PAGE_PATH = '/registration';
export const BALLOT_RESULT_PAGE_PATH = '/ballotResult';
export const SALES_BROCHURE_FOR_PARKING_SPACE_PAGE_PATH =
  '/salesBrochureForParkingSpace';
export const PARKING_SPACE_DOCUMENT_PAGE_PATH = '/parkingSpaceDocument';
