import React, { useContext } from 'react';
import { NavList } from '@src/components/NavList';
import { List } from '@src/components/NavList/List';
import { allDataContext } from '@src/contexts/allData';
import { PAGE_NAME } from '@src/constants/common';

import './index.less';

const AerialPhotographPage = () => {
  const { files } = useContext(allDataContext);
  const fileList = files?.[PAGE_NAME.AERIAL_PHOTOGRAPH] || [];

  return (
    <div className="ap-wrapper section-wrapper">
      <NavList
        titleKey="aerial_photograph"
        navListWrapperClassName="nav-list-wrapper-ap"
        listItemDividerClassName="nav-list-divider-ap"
        listItemTitleClassName="nav-list-title-ap"
        listContentWrapperClassName="nav-list-content-wrapper-ap"
        content={<List items={fileList} />}
        itemCount={fileList.length}
      />
    </div>
  );
};

export default AerialPhotographPage;
