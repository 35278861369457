import React, { useContext } from 'react';
import { NavList } from '@src/components/NavList';
import { List } from '@src/components/NavList/List';
import { allDataContext } from '@src/contexts/allData';
import { PAGE_NAME } from '@src/constants/common';

import './index.less';

const PriceListPage = () => {
  const { files } = useContext(allDataContext);
  const fileList = files?.[PAGE_NAME.PRICE_LIST] || [];

  return (
    <div className='pl-wrapper section-wrapper'>
      <NavList
        titleKey="price_list"
        navListWrapperClassName="nav-list-wrapper-pl"
        listItemDividerClassName="nav-list-divider-pl"
        listItemTitleClassName="nav-list-title-pl"
        listContentWrapperClassName='nav-list-content-wrapper-pl'
        content={<List items={fileList} />}
        itemCount={fileList.length}
      />
    </div>
  );
};

export default PriceListPage;
