import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { NavList } from '@src/components/NavList';
import { allDataContext } from '@src/contexts/allData';
import { PAGE_NAME } from '@src/constants/common';
import arrowRight from '@src/img/arrow-right.png';
import arrowDown from '@src/img/arrow-down.png';

import './index.less';

const RegistrationPage = () => {
  const { t, i18n } = useTranslation();
  const { files } = useContext(allDataContext);
  const fileList = files?.[PAGE_NAME.REGISTRATION] || [];
  const registrationLink = fileList[0]?.['online_registration_link'] || '';
  const registrationFormLink = fileList[0]?.['link'] || '';


  const content = (
    <div className="list-wrapper">
      <div
        className={`registration-item-wrapper ${i18n.language}`}
        style={{ paddingBottom: '20px' }}
      >
        <div style={{ color: '#c0ff66' }}>{t('registration_link_title')}</div>
        {registrationLink ? (
          <div className="registration-item-link-wrapper">
            <div className={`arrow-wrapper`}>
              <img src={arrowRight} className="arrow-right" />
            </div>
            <div className="registration-file-link-container">
              <a
                className="list-file-link"
                href={registrationLink}
                target="_blank"
              >
                {t('registration_link_label')}
              </a>
            </div>
          </div>
        ) : (
          <div
            className="registration-item-link-wrapper"
            style={{ justifyContent: 'flex-end' }}
          >
            <span>{t('coming_soon')}</span>
          </div>
        )}
      </div>

      <div className={`registration-item-wrapper ${i18n.language}`}>
        <div style={{ color: '#c0ff66' }}>
          {t('download_registration_form')}
        </div>
        {registrationFormLink ? (
          <div className="registration-item-link-wrapper">
            <div className={`arrow-wrapper`}>
              <img src={arrowDown} className="arrow-down" />
            </div>
            <div className="registration-file-link-container">
              <a
                className="list-file-link"
                href={registrationFormLink}
                target="_blank"
              >
                {t('download')}
              </a>
            </div>
          </div>
        ) : (
          <div
            className="registration-item-link-wrapper"
            style={{ justifyContent: 'flex-end' }}
          >
            <span>{t('coming_soon')}</span>
          </div>
        )}
      </div>
    </div>
  );

  return (
    <div className="registration-wrapper section-wrapper">
      <NavList
        titleKey="registration"
        navListWrapperClassName="nav-list-wrapper-registration"
        listItemDividerClassName="nav-list-divider-registration"
        listItemTitleClassName="nav-list-title-registration"
        listContentWrapperClassName="nav-list-content-wrapper-registration"
        content={content}
        itemCount={fileList.length}
      />
    </div>
  );
};

export default RegistrationPage;
