import React, { useEffect, useState } from 'react';
import Routes from '@src/routes/index';
import { allDataContext, defaultAllData } from './contexts/allData';
import { apiClient } from './utils/apiClient';
import { processApiData } from './utils/dataUtils';
import { ProcessApiData } from './types';

import './App.less';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { mockAllData } from './mockAllData';

const App = () => {
  const [allData, setAllData] = useState<ProcessApiData>(defaultAllData);
  const [isLoading, setIsLaoding] = useState(true);

  const fetchData = async () => {
    try {
       const response: any = await apiClient().get(
        `${window.location.origin}/api/data`,
      );
      /* const response: any = await apiClient().get(
        `http://theyohohub2.devgslb.shkp.com/api/data`,
      ); */

      setAllData(processApiData(response.data));
      // setAllData(processApiData(mockAllData));
    } catch (e) {
      console.error(e);
    }

    setIsLaoding(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <allDataContext.Provider value={{ ...allData, isLoading }}>
      <div className={'app-wrapper'}>
        <Routes />
      </div>
    </allDataContext.Provider>
  );
};

export default App;
