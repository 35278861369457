import React, { useContext } from 'react';
import { NavList } from '@src/components/NavList';
import { List } from '@src/components/NavList/List';
import { allDataContext } from '@src/contexts/allData';
import { PAGE_NAME } from '@src/constants/common';

import './index.less';

const SalesBrochureForParkingSpacePage = () => {
  const { files } = useContext(allDataContext);
  const fileList = files?.[PAGE_NAME.SALES_BROCHURE_FOR_PARKING_SPACE] || [];

  return (
    <div className='sbfps-wrapper section-wrapper'>
       <NavList
        titleKey="sales_brochure_for_parking_space"
        navListWrapperClassName="nav-list-wrapper-sbfps"
        listItemDividerClassName="nav-list-divider-sbfps"
        listItemTitleClassName="nav-list-title-sbfps"
        content={<List items={fileList} />}
        itemCount={fileList.length}
      />
    </div>
  );
};

export default SalesBrochureForParkingSpacePage;
