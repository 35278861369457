import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavList } from '@src/components/NavList';
import { List } from '@src/components/NavList/List';
import { allDataContext } from '@src/contexts/allData';
import { PAGE_NAME } from '@src/constants/common';
import { PopupInformation } from '@src/components/PopupInformation';
import { PopupInformationSm } from '@src/components/PopupInformation/PopupInformationSm';
import { useMediaQuery } from '@src/hooks/useMediaQuery';
import iconClose from '@src/img/icon-close.png';
import { SectionFile } from '@src/types';

import './index.less';

const ShowFlatPage = () => {
  const isMobile = useMediaQuery();
  const { t, i18n } = useTranslation();
  const { virtualTours } = useContext(allDataContext);
  const details = virtualTours[PAGE_NAME.SHOW_FLAT] || [];
  const [selectedItem, setSelectedItem] = useState<SectionFile | null>(null);
  const [popupVisible, setPopupVisible] = useState(false);

  const handleClickItem = (item: SectionFile) => {
    setSelectedItem(item);

    if (item[`disclaimer_${i18n.language}`]) {
      setPopupVisible(true);
    }
  };

  const handleClosePopup = () => {
    setPopupVisible(false);
  };

  useEffect(() => {
    if (!selectedItem && details[0]) {
      setSelectedItem(details[0]);

      if (details[0][`disclaimer_${i18n.language}`]) {
        setPopupVisible(true);
      }
    }
  }, [details]);

  return (
    <>
      {popupVisible && !isMobile && (
        <PopupInformation
          currentContent=""
          customTitle={t('virtual_tour_disclaimer_title')}
          customContent={selectedItem?.[`disclaimer_${i18n.language}`] || ''}
        />
      )}
      {popupVisible && isMobile && (
        <PopupInformationSm
          isPopupVisible={popupVisible}
          currentContent={''}
          customTitle={t('virtual_tour_disclaimer_title')}
          customContent={selectedItem?.[`disclaimer_${i18n.language}`] || ''}
        />
      )}
      {popupVisible && isMobile && <div className="popup-mask" />}
      {popupVisible && (
        <img
          src={iconClose}
          className={`popup-close-icon custom`}
          onClick={handleClosePopup}
        />
      )}
      <div className="sf-wrapper section-wrapper">
        <div className="sf-content">
          {selectedItem?.link && (
            <div
              className={`iframe-wrapper-sf ${
                popupVisible ? `popup-visible` : ''
              }`}
            >
              <iframe className="iframe-sf" src={selectedItem.link}></iframe>
            </div>
          )}
          <NavList
            titleKey="show_flat"
            navListWrapperClassName="nav-list-wrapper-sf"
            listItemDividerClassName="nav-list-divider-sf"
            listItemTitleClassName="nav-list-title-sf"
            content={
              <List
                items={details}
                onItemClick={handleClickItem}
                showLink={false}
              />
            }
            itemCount={details.length}
          />
        </div>
      </div>
    </>
  );
};

export default ShowFlatPage;
