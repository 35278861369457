import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';
import { layoutContext } from 'src/contexts/layout';
import yohoHub2Logo from '@src/img/logo_yohohub2.png';
import { LANGUAGE, Language, PAGE_NAME } from '@src/constants/common';
import { ENTRANCE_PATH, HOME_PAGE_PATH, ROOT_PATH } from '@src/constants/paths';
import { MenuDrawer } from '@src/components/MenuDrawer';
import { Footer } from '@src/components/Footer';
import { FooterSM } from '@src/components/Footer/FooterSM';
import { PopupInformationSm } from '@src/components/PopupInformation/PopupInformationSm';
import {
  popupContent,
  PopupInformation,
} from '@src/components/PopupInformation';
import { pathBkgImgMap } from '@src/constants/pathBkgImgMap';
import { useMediaQuery } from '@src/hooks/useMediaQuery';
import { includes, pathOr } from '@src/utils/fp';
import iconClose from '@src/img/icon-close.png';
import bgm from '@src/audio/bgm.mp3';

import './index.less';
import { allDataContext } from '@src/contexts/allData';

type Props = {
  children: React.ReactNode;
};

export const Layout = ({ children }: Props) => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const isMobile = useMediaQuery();
  const { isAnnouncementDisabled, isLoading } = useContext(allDataContext);
  const shouldShowMenuDrawer = !includes(location.pathname, [
    ROOT_PATH,
    HOME_PAGE_PATH,
    ENTRANCE_PATH,
  ]);
  const { lang }: { lang?: Language } = queryString.parse(location.search);
  const audioRef = useRef<HTMLAudioElement>(null);

  const [bkgImg, setBkgImg] = useState('');
  const [bkgColor, setBkgColor] = useState('black');
  const [bkgColorGradient, setBkgColorGradient] = useState([]);
  const [bkgPosition, setBkgPosition] = useState('center');
  const [pathName, setPathName] = useState(location.pathname);
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [isSoundPlaying, setIsSoundPlaying] = useState(false);
  const [isInformationDrawerVisible, setIsInformationDrawerVisible] = useState(
    false,
  );
  const [currPopupContentIndex, setCurrentPopupContentIndex] = useState(0);
  const [animationName, setAnimationName] = useState('');

  const handleClosePopup = () => {
    if (currPopupContentIndex > 0 || isMobile) {
      setIsPopupVisible(false);
      setCurrentPopupContentIndex(isAnnouncementDisabled ? 1 : 0);
    } else {
      setCurrentPopupContentIndex((index) => index + 1);
    }
  };

  const handleClickInformation = () => {
    setIsInformationDrawerVisible((isVisible) => !isVisible);
  };

  const handleClickInformationMobile = () => {
    setCurrentPopupContentIndex(1);
    setIsPopupVisible(true);
  };

  const handleClickCopyright = () => {
    setCurrentPopupContentIndex(2);
    setIsPopupVisible(true);
  };

  const handleCloseInformationDrawer = () => {
    setIsInformationDrawerVisible(false);
  };

  const handleChangePath = async (newPathName: string) => {
    if (!isMobile && newPathName !== pathName) {
      setAnimationName('curtainRollDown');

      if (isDrawerVisible) setIsDrawerVisible(false);

      await new Promise((res) => setTimeout(res, 500));
    }
  };

  const toggleSoundIcon = () => setIsSoundPlaying(!isSoundPlaying);

  useEffect(() => {
    const isValidLang = includes(lang, Object.values(LANGUAGE));
    if (lang && isValidLang) {
      i18n.changeLanguage(lang);
    } else {
      history.push({
        search: `?lang=${LANGUAGE.ENG}`,
      });
    }
  }, [lang]);

  useEffect(() => {
    setBkgImg(
      pathOr(
        '',
        [location.pathname, isMobile ? 'mobile' : 'default'],
        pathBkgImgMap,
      ),
    );
    setBkgColor(pathOr('black', [location.pathname, 'color'], pathBkgImgMap));
    setBkgColorGradient(
      pathOr([], [location.pathname, 'gradient'], pathBkgImgMap),
    );
    setBkgPosition(
      pathOr('center', [location.pathname, 'position'], pathBkgImgMap),
    );

    if (isDrawerVisible) setIsDrawerVisible(false);

    if (location.pathname === HOME_PAGE_PATH && !isLoading) {
      setCurrentPopupContentIndex(isAnnouncementDisabled ? 1 : 0);
      setIsPopupVisible(true);
    }

    if (
      !isMobile &&
      location.pathname !== pathName &&
      !includes(location.pathname, [ENTRANCE_PATH, ROOT_PATH])
    ) {
      setAnimationName('curtainRollUp');
    }

    if (location.pathname !== pathName) {
      setPathName(location.pathname);
    }
  }, [location.pathname, isMobile, isLoading, isAnnouncementDisabled]);

  useEffect(() => {
    isSoundPlaying
      ? audioRef.current && audioRef.current.play()
      : audioRef.current && audioRef.current.pause();
  }, [isSoundPlaying]);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.addEventListener('ended', () =>
        setIsSoundPlaying(false),
      );

      setTimeout(() => {
        setIsSoundPlaying(true);
      }, 1000);

      return () => {
        audioRef.current?.removeEventListener('ended', () =>
          setIsSoundPlaying(false),
        );
      };
    }
  }, []);

  return (
    <layoutContext.Provider value={{ onChangeRoute: handleChangePath }}>
      {!isMobile && <div className="curtain" style={{ animationName }} />}
      <div
        className={`layout-wrapper ${i18n.language}`}
        style={{
          backgroundImage:
            'url(' +
            bkgImg +
            `)${
              bkgColorGradient.length
                ? `, linear-gradient(to bottom, ${bkgColorGradient[0]}, ${bkgColorGradient[1]})`
                : ''
            }`,
          backgroundColor: bkgColor,
          backgroundPosition: bkgPosition,
        }}
      >
        {isPopupVisible && (
          <img
            src={iconClose}
            className={`popup-close-icon ${popupContent[currPopupContentIndex]}`}
            onClick={handleClosePopup}
          />
        )}
        {isPopupVisible && isMobile && (
          <PopupInformationSm
            isPopupVisible={isPopupVisible}
            currentContent={popupContent[currPopupContentIndex]}
          />
        )}
        {isPopupVisible && !isMobile && (
          <PopupInformation
            currentContent={popupContent[currPopupContentIndex]}
          />
        )}
        {isMobile && isPopupVisible && <div className="popup-mask" />}
        {location.pathname !== ROOT_PATH && (
          <Link
            to={{ pathname: HOME_PAGE_PATH, search: `?lang=${i18n.language}` }}
          >
            <img className="yohohub2-logo" src={yohoHub2Logo} />
          </Link>
        )}
        <div
          className={`page-wrapper ${isPopupVisible ? `popup-visible` : ''}`}
        >
          {children}
        </div>
        {(!isMobile || location.pathname !== ROOT_PATH) &&
          isInformationDrawerVisible && (
            <InformationDrawer onClose={handleCloseInformationDrawer} />
          )}
        {!isMobile && location.pathname !== ROOT_PATH && (
          <Footer
            onClickInformation={handleClickInformation}
            onClickCopyright={handleClickCopyright}
            onToggleSoundIcon={toggleSoundIcon}
            isSoundPlaying={isSoundPlaying}
          />
        )}
        {isMobile && location.pathname !== ROOT_PATH && (
          <FooterSM
            onClickInformation={handleClickInformationMobile}
            onClickCopyright={handleClickCopyright}
          />
        )}
        {shouldShowMenuDrawer && (
          <MenuDrawer
            isVisible={isDrawerVisible}
            onOpen={() => setIsDrawerVisible(true)}
            onClose={() => setIsDrawerVisible(false)}
            selectedPagePath={location.pathname}
          />
        )}
        {!isMobile && (
          <audio ref={audioRef} loop autoPlay style={{ display: 'none' }}>
            <source type="audio/mp3" src={bgm} />
          </audio>
        )}
      </div>
    </layoutContext.Provider>
  );
};

const InformationDrawer = ({ onClose }: { onClose: () => void }) => {
  const { t, i18n } = useTranslation();
  const { lastUpdatedEn, lastUpdatedCh } = useContext(allDataContext);

  return (
    <div className={`information-drawer ${i18n.language}`}>
      <img
        src={iconClose}
        className={'information-drawer-close-icon'}
        onClick={onClose}
      />
      <div className="information-drawer-content">
        <p className="information-drawer-content-title">
          {t('information_a')} {t('information_b')}
        </p>
        <p>
          {t('information_content_1a')}
          <br />
          {t('information_content_1b')}
        </p>
        <p>
          {t('information_content_2a')}
          <br />
          {t('information_content_2aa')}
          <br />
          {t('information_content_2b')}
        </p>
        <p>
          {t('information_content_2c')}
        </p>
        <p>
          {t('information_content_3a')}
          ｜
          {t('information_content_3b')}
          ｜
          {t('information_content_3c')}
          ｜
          {t('information_content_3d')}
          ｜
          {t('information_content_3e')}
          ｜
          {t('information_content_4a')}
          ｜
          {t('information_content_4b')}
          ｜
          {t('information_content_4c')}
          ｜
          {t('information_content_4d')}
          ｜
          {t('information_content_5')}
          ｜
          {t('information_content_9')}
        </p>
        <p>
          {t('information_content_7')}
        </p>
      </div>
    </div>
  );
};
