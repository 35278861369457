import * as R from 'ramda';

export const omit = R.omit;
export const range = R.range;
export const includes = R.includes;
export const groupBy = R.groupBy;
export const mapObjIndexed = R.mapObjIndexed;
export const pipe = R.pipe;
export const propOr = R.propOr;
export const pathOr = R.pathOr;
export const map = R.map;
export const isNil = R.isNil;

export const isNilOrEmpty = (val: any) => R.isEmpty(val) || R.isNil(val);
