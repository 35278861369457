import { format } from 'date-fns';
import {
  ApiData,
  ApiDataItem,
  ProcessApiData,
  VirtualTour,
  VirtualTourApiData,
} from '@src/types';
import { groupBy, mapObjIndexed, pipe, propOr } from './fp';

export const processApiData = (apiData: ApiData): ProcessApiData => {
  const {
    items = [],
    virtualTours,
    lastUpdated = '',
    announcements = {
      sc: '',
      en: '',
      zh: '',
    },
  } = apiData;

  let lastUpdatedEn = '';
  let lastUpdatedCh = '';

  if (lastUpdated) {
    const date = new Date(lastUpdated);

    lastUpdatedEn = format(date, 'do MMM yyyy');

    const dateAItems = format(date, 'yyyy-M-d').split('-');
    lastUpdatedCh = `${dateAItems[0]}年${dateAItems[1]}月${dateAItems[2]}日`;
  }

  return {
    files: pipe(
      groupBy(propOr('undefined', 'section')),
      mapObjIndexed((files: ApiDataItem[]) => {
        return files.map(
          ({ title_en, title_sc, title_zh, file_path, ...rest }) => {
            return {
              en: title_en,
              zh: title_zh,
              sc: title_sc,
              link: file_path,
              ...rest,
            };
          },
        );
      }),
    )(items),
    virtualTours: pipe(
      groupBy(propOr('undefined', 'type')),
      mapObjIndexed((files: VirtualTourApiData[]) => {
        return files.map(
          ({
            title_en,
            title_sc,
            title_zh,
            sub_title_en,
            sub_title_sc,
            sub_title_zh,
            disclaimer_en,
            disclaimer_sc,
            disclaimer_zh,
            link,
            thumbnail_path,
          }) => {
            return {
              en: title_en,
              zh: title_zh,
              sc: title_sc,
              sub_title_en,
              sub_title_sc,
              sub_title_zh,
              disclaimer_en,
              disclaimer_sc,
              disclaimer_zh,
              link,
              thumbnail_path,
            };
          },
        );
      }),
    )(virtualTours),
    lastUpdated,
    announcements,
    lastUpdatedEn,
    lastUpdatedCh,
    isAnnouncementDisabled: Object.values(announcements).every(
      (val) => val === '',
    ),
  };
};
