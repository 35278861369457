import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollArrowDown, ScrollArrowUp } from '../ScrollArrow';

import './index.less';

type Props = {
  titleKey: string;
  content?: React.ReactNode;
  navListWrapperClassName: string;
  listItemDividerClassName: string;
  listItemTitleClassName: string;
  listItemWrapperClassName?: string;
  listContentWrapperClassName?: string;
  itemCount: number;
};

export const NavList = ({
  titleKey,
  content,
  navListWrapperClassName = '',
  listItemDividerClassName = '',
  listItemTitleClassName = '',
  listContentWrapperClassName = '',
  listItemWrapperClassName = '',
  itemCount,
}: Props) => {
  const contentRef = useRef(null);
  const { t, i18n } = useTranslation();

  return (
    <div className={`nav-list-wrapper ${navListWrapperClassName}`}>
      {content && itemCount > 1 && <ScrollArrowUp targetRef={contentRef} />}
      <div
        className={`nav-list-divider ${listItemDividerClassName} lang-${i18n.language}`}
      />
      <div className={`list-title ${listItemTitleClassName}`}>
        <span>{t(titleKey)}</span>
      </div>
      {content && (
        <div
          className={`nav-list-content-wrapper lang-${i18n.language} ${listContentWrapperClassName}`}
          ref={contentRef}
        >
          {content}
        </div>
      )}
      <div
        className={`nav-list-divider ${listItemDividerClassName} lang-${i18n.language}`}
      />
      {content && itemCount > 1 && <ScrollArrowDown targetRef={contentRef} />}
    </div>
  );
};

export default NavList;
